<script>
import { app } from '@/main'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { mapState, mapActions, mapGetters } from 'vuex'

const TRANSLATION = {
  feedback: {
    create: {
      success: app.$t('community.users:register.success'),
      duplicatedUsername: app.$t('community.users:feedback.created.duplicated_username')
    },
    update: {
      success: app.$t('community.users:update.success'),
      addressNotFound: app.$t('community.users:feedback.updated.address_not_found'),
      invalidCurrentPassword: app.$t('community.users:feedback.updated.invalid_current_password')
    },
    save: {
      userNotFound: app.$t('community.users:feedback.updated.user_not_found'),
      duplicatedEmail: app.$t('global:form.validation.email'),
      usernameIsntComposite: app.$t('community.users:feedback.save.username_composite'),
      validationError: app.$t('users.manage:validation.error')
    },
    error: app.$t('global.error')
  }
}

const CREATE_ERRORS_TYPE = {
  user_already_exists: TRANSLATION.feedback.create.duplicatedUsername,
  email_already_exists: TRANSLATION.feedback.save.duplicatedEmail,
  user_name_is_not_composite: TRANSLATION.feedback.save.usernameIsntComposite,
  validation_error: TRANSLATION.feedback.save.validationError,
  default: TRANSLATION.feedback.error
}

const UPDATE_ERRORS_TYPE = {
  user_not_exists: TRANSLATION.feedback.save.userNotFound,
  duplicated_email: TRANSLATION.feedback.save.duplicatedEmail,
  user_name_is_not_composite: TRANSLATION.feedback.save.usernameIsntComposite,
  invalid_current_password: TRANSLATION.feedback.update.invalidCurrentPassword,
  address_not_found: TRANSLATION.feedback.update.addressNotFound,
  validation_error: TRANSLATION.feedback.save.validationError,
  default: TRANSLATION.feedback.error
}

export default {
  name: 'UsersManage',
  directives: { mask },
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    // Radio: () => import('@/components/general/Radio'),
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Checkbox: () => import('@/components/general/Checkbox'),
    Datepicker: () => import('@/components/general/Datepicker'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    Radio: () => import('@/components/general/Radio'),
    Autocomplete: () => import('@/components/general/Autocomplete'),
    AppMultiselect: () => import('@/components/general/AppMultiselect')
  },

  data () {
    return {
      selectedProfile: null,
      userStatusOptions: [
        {
          label: 'Completo e validado',
          value: 'completed_and_validated'
        },
        {
          label: 'Completo e não validado',
          value: 'completed_and_not_validated'
        },
        {
          label: 'Incompleto',
          value: 'incomplete'
        }
      ],

      formData: {
        id: null,
        name: null,
        email: null,
        username: null,
        gender: null,
        educationLevelId: null,
        targetAudienceId: null,
        status: 'incomplete',
        targetAudience: {
          targetAudienceQuestion: null
        },

        targetAudienceQuestion: [],
        data: {
          celular: null,
          birthOfDate: null,
          gender: null,
          notificationType: {
            correio: false,
            email: true,
            sms: false,
            telefone: false
          },

          responsibleTutor: null,
          cpfResponsibleTutor: null,

          address: {
            pais: 'BR',
            id_state: '',
            uf: null,
            id_city: null,
            cidade: null,
            bairro: null,
            cep: '',
            logradouro: null,
            tipo_logradouro: null,
            numero: null,
            complemento: null,
            IdTipoLogradouro: 1001
          }
        },

        company: {
          id: null,
          cnpj: '',
          companyName: null,
          tradingName: null,
          foundingDate: null,
          phone: null,
          situation_alias: 'natural_person',
          businessSectorIds: [],
          businessSizeId: null,
          cnaeIds: [],
          data: {
            address: {
              pais: 'BR',
              id_state: '',
              uf: null,
              id_city: null,
              cidade: null,
              bairro: null,
              cep: '',
              logradouro: null,
              tipo_logradouro: null,
              numero: null,
              complemento: null,
              IdTipoLogradouro: 1001
            }
          }
        },

        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
        password: null,
        groups: []
      },

      personSituationOptions: [
        {
          label: this.$t('users.manage:company.situation.company_owner'),
          value: 'company_owner'
        },
        {
          label: this.$t('users.manage:company.situation.natural_person'),
          value: 'natural_person'
        }
      ],

      genderOptions: [
        {
          text: this.$t('global.genre:FEMALE'),
          value: 'F'
        },
        {
          text: this.$t('global.genre:MALE'),
          value: 'M'
        }
      ],

      notificationOptions: {
        email: [
          {
            label: this.$t('global:form.email'),
            value: false
          }
        ],

        telefone: [
          {
            label: this.$t('global:form.phone'),
            value: false
          }
        ],

        correio: [
          {
            label: this.$t('global:form.mail'),
            value: false
          }
        ],

        sms: [
          {
            label: this.$t('global:form.sms'),
            value: false
          }
        ]
      },

      educationLevels: [],
      targetAudiences: [],
      states: [],
      userCities: [],
      companyCities: [],
      currentCep: '',
      businessSectors: [],
      businessSizes: [],
      cnaes: [],
      groupList: [],
      userGroups: [],
      profileOptions: [
        {
          label: 'Administrador',
          value: 'admin'
        },
        {
          label: 'Estudante',
          value: 'user'
        }
      ],

      tabLinks: [
        {
          text: 'community.index:modal.tab.link.personal.data'
        }
      ],

      tabLinkActive: 0,
      allowChangeTab: true,
      userTargetAudience: null,
      olderThan18: true
    }
  },

  validations: {
    formData: {
      id: {
        required: requiredIf(function () {
          return this.isEditing
        })
      },

      name: { required },
      email: {
        required,
        email
      },

      username: {
        required,
        usernameValidator: (value) => {
          if (value) {
            const regex = /^[a-z0-9_!@#$%^&*(),.?":{}|<>;'[\]`~+=-]+$/

            return regex.test(value)
          }
        }
        // cpfValidation: minLength(14)
      },

      // educationLevelId: {
      //   required: requiredIf(function () {
      //     return this.isEditing
      //   })
      // },

      // targetAudienceId: {
      //   required: requiredIf(function () {
      //     return this.isEditing
      //   })
      // },

      // targetAudience: {
      //   targetAudienceQuestion: {
      //     $each: {
      //       required: requiredIf(function (item) {
      //         return this.formData.targetAudience && item.required
      //       }),

      //       value: {
      //         required: requiredIf(function (item) {
      //           return this.formData.targetAudience && item.required
      //         })
      //       }
      //     }
      //   }
      // },

      password: {
        required: requiredIf(function () {
          return !this.isEditing
        }),

        regexPassword: function (event) {
          if (event === null || (this.isEditing && event === '')) {
            return true
          }

          const regexPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})')

          return regexPassword.test(event)
        }
      }

      // data: {
      //   celular: {
      //     required: requiredIf(function () {
      //       return this.isEditing
      //     }),

      //     phoneValidation: function (item) {
      //       if (this.isEditing) {
      //         return phoneValidator(item)
      //       }

      //       return true
      //     }
      //   },

      //   birthOfDate: {
      //     required: requiredIf(function () {
      //       return this.isEditing
      //     }),

      //     birthday: minLength(10)
      //   },

      //   responsibleTutor: {
      //     required: requiredIf(function () {
      //       return !this.olderThan18
      //     })
      //   },

      //   cpfResponsibleTutor: {
      //     required: requiredIf(function () {
      //       return !this.olderThan18
      //     })
      //   },

      //   gender: {
      //     required: requiredIf(function () {
      //       return this.isEditing
      //     })
      //   },

      //   address: {
      //     id_state: {
      //       required: requiredIf(function () {
      //         return this.isEditing && (this.isRaeEnabled || this.isZipCodeEnabled)
      //       })
      //     },

      //     id_city: {
      //       required: requiredIf(function () {
      //         return this.isEditing && (this.isRaeEnabled || this.isZipCodeEnabled)
      //       })
      //     },

      //     bairro: {
      //       required: requiredIf(function () {
      //         return this.isEditing && (this.isRaeEnabled || this.isZipCodeEnabled)
      //       })
      //     },

      //     logradouro: {
      //       required: requiredIf(function () {
      //         return this.isEditing && (this.isRaeEnabled || this.isZipCodeEnabled)
      //       })
      //     },

      //     numero: {
      //       required: requiredIf(function () {
      //         return this.isEditing && (this.isRaeEnabled || this.isZipCodeEnabled)
      //       })
      //     },

      //     cep: {
      //       required: requiredIf(function () {
      //         return this.isEditing && (this.isRaeEnabled || this.isZipCodeEnabled)
      //       }),

      //       minLength: function (value) {
      //         return (this.isZipCodeEnabled || this.isRaeEnabled) ? minLength(9)(value) : true
      //       }
      //     }
      //   }
      // },

      // company: {
      //   cnpj: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     }),

      //     cnpjValidation: function (value) {
      //       if (!this.canReleaseCompanyFields()) return true

      //       return cnpjValidator(value)
      //     },

      //     minLength: minLength(18)
      //   },

      //   companyName: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     })
      //   },

      //   tradingName: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     })
      //   },

      //   foundingDate: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     })
      //   },

      //   phone: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     }),

      //     phoneValidation: function (item) {
      //       if (!this.canReleaseCompanyFields()) return true

      //       return phoneValidator(item)
      //     }
      //   },

      //   situation_alias: { required },
      //   businessSectorIds: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     })
      //   },

      //   businessSizeId: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     })
      //   },

      //   cnaeIds: {
      //     required: requiredIf(function () {
      //       return this.canReleaseCompanyFields()
      //     })
      //   },

      //   data: {
      //     address: {
      //       id_state: {
      //         required: requiredIf(function () {
      //           return this.canReleaseCompanyFields()
      //         })
      //       },

      //       id_city: {
      //         required: requiredIf(function () {
      //           return this.canReleaseCompanyFields()
      //         })
      //       },

      //       bairro: {
      //         required: requiredIf(function () {
      //           return this.canReleaseCompanyFields()
      //         })
      //       },

      //       logradouro: {
      //         required: requiredIf(function () {
      //           return this.canReleaseCompanyFields()
      //         })
      //       },

      //       numero: {
      //         required: requiredIf(function () {
      //           return this.canReleaseCompanyFields()
      //         })
      //       },

      //       cep: {
      //         required: requiredIf(function () {
      //           return this.canReleaseCompanyFields()
      //         }),

      //         minLength: minLength(9)
      //       }
      //     }
      //   }
      // }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    ...mapGetters([ 'activeModules' ]),
    isEditing () {
      return this.$store.getters.getManagingUser || this.$route.params.id || false
    },

    hasStudentProfile () {
      return (this.formData.profile && this.formData.profile.find(item => item === 'user')) || true
    },

    parsedTargetAudiences () {
      return this.targetAudiences.map(item => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },

    isRaeEnabled () {
      return this.activeModules.includes('rae')
    },

    isZipCodeEnabled () {
      return this.activeModules.includes('zip-code')
    },

    isActiveTargetAudienceModule () {
      return this.activeModules.includes('target-audience')
    }
  },

  watch: {
    'formData.company.situation_alias' () {
      if (this.formData.company.situation_alias === 'company_owner') {
        this.tabLinks.push(
          {
            text: 'community.index:modal.tab.link.corporate.data'
          }
        )

        this.getCompanySelectsData()

        if (this.allowChangeTab) {
          this.tabLinkActive = 1

          this.debounceEvent(() => {
            document.querySelector('.modal-blocker')
              .scroll({
                top: 0,
                behavior: 'smooth'
              })
          }, 500)
        }
      } else if (this.tabLinks.length === 2) {
        this.tabLinks.pop()
      }

      this.allowChangeTab = true
    },

    'formData.data.address.cep' () {
      if (!this.formData.data?.address?.cep) {
        return
      }

      const newCep = this.formData.data.address.cep.replace(/[^0-9]+/ig, '')

      if (newCep === this.currentCep.replace(/[^0-9]+/ig, '')) {
        return
      }

      if (newCep.length === 8) {
        this.getUserAddressByCep()
        this.currentCep = newCep
      }
    },

    'formData.company.data.address.cep' () {
      if (this.formData.company.data.address.cep.length === 9) {
        this.getCompanyAddressByCep()
      }
    },

    // 'formData.data.address.id_state' () {
    //   this.ListCitiesByState(this.formData.data.address.id_state, 'user')
    // },

    // 'formData.company.data.address.id_state' () {
    //   this.ListCitiesByState(this.formData.company.data.address.id_state, 'company')
    // },

    'formData.company.cnpj' () {
      if (this.formData.company.cnpj.length === 18) {
        this.formData.company.id = null
        this.getCompanyDataByCnpj()
      }
    },

    // 'formData.username' () {
    //  if (!this.isEditing && this.formData.username.length === 14) {
    //    this.getUserDataByCpf()
    //  }
    // },

    'formData.targetAudienceId' (newVal) {
      if (!this.formData.targetAudienceId) {
        return
      }

      this.formData.targetAudience = this.userTargetAudience

      if (this.formData.targetAudience?.id !== newVal) {
        const selectedTargetAudience = this.targetAudiences.filter(profile => {
          return profile.id === this.formData.targetAudienceId ? profile : null
        })

        this.formData.targetAudience = selectedTargetAudience && selectedTargetAudience.length > 0
          ? selectedTargetAudience[0]
          : null
      }

      if (!this.formData.targetAudience) {
        return
      }

      const question = this.formData.targetAudience.targetAudienceQuestion

      this.formData.targetAudience.targetAudienceQuestion = question.map((question) => {
        return {
          id: question.id,
          title: question.title,
          type: question.type,
          required: question.required,
          value: this.getTargetAudienceValue(question.targetAudienceAnswer),
          targetAudienceQuestionOption: question.targetAudienceQuestionOption.map(item => {
            if (item.name) {
              return {
                text: item.name,
                value: item.name
              }
            }

            return item
          })
        }
      })
    },

    'formData.data.birthOfDate' (newVal) {
      this.calculateAge(newVal)
    }
  },

  created () {
    this.setup()
    this.getSelectsData()

    if (this.isEditing) {
      this.loadUserData()
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateUser',
      'attemptGetUser',
      'attemptGetAddressByCep',
      'attemptListEducationLevels',
      'attemptListStates',
      'attemptListCities',
      'attemptListBusinessSectors',
      'attemptListBusinessSizes',
      'attemptListCnaes',
      'attemptGetCompanyDataByCnpj',
      'attemptCreateUser',
      'attemptGetUserByCpf',
      'attemptGetTargetAudiencesList',
      'attemptGetGroupsList'
    ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.CREATE_ERRORS_TYPE = CREATE_ERRORS_TYPE
      this.UPDATE_ERRORS_TYPE = UPDATE_ERRORS_TYPE
    },

    changeTab (index) {
      this.tabLinkActive = index
    },

    submit () {
      if (this.isEditing) {
        this.updateUser()

        return
      }

      this.createUser()
    },

    updateUser () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.showValidationErrors()

        return
      }

      this.setFetching(true)
      const formData = this.parseUserRequestData()

      this.attemptUpdateUser(formData)
        .then(() => {
          this.setFeedback({ message: TRANSLATION.feedback.update.success })
          this.leave()
        })
        .catch((response) => {
          const error = response.data.error

          if (UPDATE_ERRORS_TYPE[error.message]) {
            this.setFeedback({ message: UPDATE_ERRORS_TYPE[error.message] })

            return
          }

          /**
           * TODO: Remover essa validação após a correção do endpoint de update do usuário. Sem validar o gdh.
           *
           * Essa validação do GDH não deveria estar acontecendo no ambiente de gestão.
           * O mesmo está ocorrendo pois o endpoint para fazer update do usuário é o mesmo utilizado no portal.
           *
           * Avaliar a possibilidade de criar um endpoint específico para o ambiente de gestão.
           *
           * Esse problema foi encontrado durante a task dos webhooks, em específico: https://dot-group.atlassian.net/browse/VTNN-622
           */
          const isGdhError = response.data.error?.message?.toLowerCase()?.includes('gdh')

          if (!isGdhError) {
            this.setFeedback({ message: UPDATE_ERRORS_TYPE.default })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    createUser () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.showValidationErrors()

        return
      }

      this.setFetching(true)
      const formData = this.parseUserRequestData()

      this.attemptCreateUser(formData)
        .then(() => {
          this.setFeedback({ message: TRANSLATION.feedback.create.success })
          this.leave()
        })
        .catch((response) => {
          const error = response.data.error

          if (CREATE_ERRORS_TYPE[error.message]) {
            this.setFeedback({ message: CREATE_ERRORS_TYPE[error.message] })

            return
          }

          this.setFeedback({ message: CREATE_ERRORS_TYPE.default })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    parseUserRequestData () {
      const formData = JSON.parse(JSON.stringify(this.formData))

      // formData.username = formData.username.replace(/[^0-9]+/ig, '')
      formData.isAdmin = true

      formData.data = Object.fromEntries(Object.entries(formData.data)
        .filter(([ value ]) => !!value))

      if (this.formData.data.address.id_state) {
        const state = this.states.find(element =>
          element.value === this.formData.data.address.id_state
        )

        formData.data.address.uf = state.text
      }

      const city = this.userCities.find(element => {
        return element.value === this.formData.data.address.id_city
      })

      formData.data.address.cidade = city ? city.text : ''

      formData.groups = this.userGroups.map(item => item.value)

      if (!this.canReleaseCompanyFields()) {
        formData.company = null

        return formData
      }

      formData.company.cnpj = formData.company.cnpj.replace(/[^0-9]+/ig, '')
      const cnaes = []

      formData.company.cnaeIds.forEach(item => {
        cnaes.push(item.code)
      })

      formData.company.cnaeIds = cnaes

      const companyState = this.states.find(element =>
        element.value === this.formData.company.data.address.id_state
      )
      const companyCity = this.companyCities.find(element =>
        element.value === this.formData.company.data.address.id_city
      )

      formData.company.data.address.uf = companyState.text
      formData.company.data.address.cidade = companyCity?.text

      return formData
    },

    showValidationErrors () {
      this.$nextTick(() => {
        if (this.tabLinks.length === 2 && this.tabLinkActive === 0 && this.$v.formData.company.$error) {
          this.tabLinkActive = 1
        }

        if (this.tabLinkActive === 1 && !this.$v.formData.company.$error && this.$v.formData.$error) {
          this.tabLinkActive = 0
        }

        this.setFeedback({ message: this.$t('users.manage:validation.error') })

        this.debounceEvent(() => {
          const hasErrorElements = document.querySelector('.has-error')
          const top = hasErrorElements.offsetTop - 50

          document.querySelector('.modal-blocker')
            .scroll({
              top: top,
              behavior: 'smooth'
            })
        }, 500)
      })
    },

    leave () {
      this.$router.push({
        name: 'users.index',
        params: { searchUserBind: this.$route.params.searchUserBind }
      })
    },

    canReleaseCompanyFields () {
      return [
        'company_owner',
        'company_employee'
      ].includes(this.formData.company.situation_alias)
    },

    getUserAddressByCep () {
      if (!this.formData.data?.address?.cep) {
        return
      }

      let cep = this.formData.data.address.cep

      if (!cep) return

      cep = cep.replace(/[^0-9]+/ig, '')

      if (cep.length === 8) {
        this.attemptGetAddressByCep(cep)
          .then(({ data }) => {
            this.formData.data.address.id_state = data.CityObject.state.id
            this.formData.data.address.logradouro = data.Logradouro
            this.formData.data.address.bairro = data.Bairro
            this.formData.data.address.IdTipoLogradouro = data.IdTipoLogradouro
            this.formData.data.address.id_city = data.CityObject.id ? data.CityObject.id : data.CityObject.alias

            this.userCities = []

            this.userCities.push({
              text: data.CityObject.name,
              value: data.CityObject.id ? data.CityObject.id : data.CityObject.alias
            })
          })
          .catch(({ data }) => {
            let message = this.$t('global:form.user.manage.validation.cep.not.found')

            if (data && data.error.message === 'address_not_found') {
              message = this.$t('global:form.user.manage.validation.cep.address.not.found')
            } else if (data && data.error.message === 'city_not_found') {
              message = this.$t('global:form.user.manage.validation.cep.city.not.found')
            }

            this.setFeedback({ message: message })
          })
      }
    },

    getCompanyAddressByCep () {
      let cep = this.formData.company.data.address.cep

      cep = cep.replace(/[^0-9]+/ig, '')

      if (cep.length === 8) {
        this.attemptGetAddressByCep(cep)
          .then(({ data }) => {
            this.formData.company.data.address.id_state = data.CityObject.state.id
            this.formData.company.data.address.logradouro = data.Logradouro
            this.formData.company.data.address.bairro = data.Bairro
            this.formData.company.data.address.IdTipoLogradouro = data.IdTipoLogradouro
            this.formData.company.data.address.id_city = data.CityObject.id ? data.CityObject.id : data.CityObject.alias
          })
      }
    },

    getSelectsData () {
      this.attemptListEducationLevels()
        .then(({ data }) => {
          data.forEach(item => {
            this.educationLevels.push({
              text: item.description,
              value: item.id
            })
          })
        })

      this.attemptListStates()
        .then(({ data }) => {
          data.forEach(item => {
            this.states.push({
              text: item.name,
              value: item.id
            })
          })
        })

      this.attemptGetTargetAudiencesList({
        filter: { active: 1 },
        order: {
          name: 'ASC',
          alias: 'ASC'
        },
        limit: 999999
      })
        .then(({ data }) => {
          data.forEach(item => {
            this.targetAudiences.push(item)
          })
        })

      this.attemptGetGroupsList({
        filter: {
          active: true
        },
        limit: 9999
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.groupList.push({
                text: element.name,
                value: element.id
              })

              if (this.formData.groups.includes(element.id)) {
                this.userGroups.push({
                  text: element.name,
                  value: element.id
                })
              }
            })

            return true
          }
        })
    },

    getCompanySelectsData () {
      this.attemptListBusinessSectors()
        .then(({ data }) => {
          this.businessSectors = []

          data.forEach(item => {
            this.businessSectors.push({
              text: item.description,
              value: item.id
            })
          })
        })

      this.attemptListBusinessSizes()
        .then(({ data }) => {
          this.businessSizes = []

          data.forEach(item => {
            this.businessSizes.push({
              text: item.description,
              value: item.id
            })
          })
        })
    },

    // ListCitiesByState (stateId, context) {
    //   this.attemptListCities(stateId)
    //     .then(({ data }) => {
    //       if (!data) {
    //         return
    //       }

    //       const cities = []

    //       data.forEach(item => {
    //         cities.push({
    //           text: item.name,
    //           value: Number(this.formData.data.address.id_city) ? item.id : item.name.toLowerCase()
    //         })
    //       })

    //       if (context === 'user') {
    //         this.userCities = cities
    //       }

    //       if (context === 'company') {
    //         this.companyCities = cities
    //       }
    //     })
    // },

    fetchCnaes (search) {
      if (search.length < 3) {
        return
      }

      const options = []

      this.attemptListCnaes(search)
        .then(({ data }) => {
          data.data.forEach(item => {
            const description = item.cnaeCode + ' - ' + item.description

            options.push({
              label: description,
              code: item.id
            })
          })
        })

      this.cnaes = options
    },

    getCompanyDataByCnpj () {
      let cnpj = this.formData.company.cnpj

      cnpj = cnpj.replace(/[^0-9]+/ig, '')

      if (cnpj.length === 14) {
        this.attemptGetCompanyDataByCnpj(cnpj)
          .then(({ data }) => {
            this.formData.company.id = data.id
            this.formData.company.companyName = data.companyName
            this.formData.company.tradingName = data.tradingName

            if (data.foundingDate) {
              this.formData.company.foundingDate = data.foundingDate
            }

            this.formData.company.phone = data.phone
            this.formData.company.businessSizeId = data.businessSize ? data.businessSize.id : null
            this.formData.company.businessSectorIds = []

            data.companyBusinessSectors.forEach(item => {
              this.formData.company.businessSectorIds.push(item.businessSector.id)
            })

            this.formData.company.cnaeIds = []
            this.cnaes = []

            data.companyCnaes.forEach(item => {
              const description = item.cnae.cnaeCode + ' - ' + item.cnae.description
              const cnaeId = item.cnae.id

              this.cnaes.push({
                label: description,
                code: cnaeId
              })

              this.formData.company.cnaeIds.push({
                label: description,
                code: cnaeId
              })
            })

            this.formData.company.data.address.cep = data.data.address.cep
            this.formData.company.data.address.id_state = parseInt(data.data.address.id_state)
            this.formData.company.data.address.id_city = Number(data.data.address.id_city) ? parseInt(data.data.address.id_city) : data.data.address.id_city
            this.formData.company.data.address.bairro = data.data.address.bairro
            this.formData.company.data.address.logradouro = data.data.address.logradouro
            this.formData.company.data.address.numero = data.data.address.numero
            this.formData.company.data.address.complemento = data.data.address.complemento
          })
      }
    },

    parseUserResponseData (data) {
      this.formData.id = data.user.id

      if (this.isEditing) {
        this.formData.username = data.user.username
      }

      this.formData.name = data.user.name
      this.formData.email = data.user.email
      this.formData.data.birthOfDate = data.user.data.birthOfDate
      this.formData.data.celular = data.user.data.celular
      this.formData.data.gender = data.user.data.gender
      this.formData.groups = data.user.groups

      if (data.user.data.responsibleTutor) {
        this.formData.data.responsibleTutor = data.user.data.responsibleTutor
      }

      if (data.user.data.cpfResponsibleTutor) {
        this.formData.data.cpfResponsibleTutor = data.user.data.cpfResponsibleTutor
      }

      if (data.user.educationLevel) {
        this.formData.educationLevelId = data.user.educationLevel.id
      }

      if (data.user.targetAudience) {
        this.userTargetAudience = data.user.targetAudience
        this.formData.targetAudienceId = this.userTargetAudience.id
      }

      if (data.user.data.notificationType) {
        const notification = data.user.data.notificationType

        this.formData.data.notificationType = {
          email: notification.email,
          telefone: notification.telefone,
          correio: notification.correio,
          sms: notification.sms
        }
      }

      if (data.user.data.address) {
        this.formData.data.address.cep = this.currentCep = data.user.data.address.cep
        this.formData.data.address.id_state = parseInt(data.user.data.address.id_state)
        this.formData.data.address.id_city = Number(data.user.data.address.id_city) ? parseInt(data.user.data.address.id_city) : data.user.data.address.id_city
        this.formData.data.address.bairro = data.user.data.address.bairro
        this.formData.data.address.logradouro = data.user.data.address.logradouro
        this.formData.data.address.numero = data.user.data.address.numero
        this.formData.data.address.complemento = data.user.data.address.complemento
      }

      if (this.isZipCodeEnabled) this.getUserAddressByCep()

      this.allowChangeTab = false
      this.formData.company.situation_alias = data.situation ? data.situation.alias : 'natural_person'

      if (this.canReleaseCompanyFields()) {
        this.formData.company.cnpj = data.company.cnpj
      }
    },

    loadUserData () {
      this.attemptGetUser(this.$route.params.id)
        .then(({ data }) => {
          this.parseUserResponseData(data)
        })
        .catch(() => {
          this.setFeedback({ message: this.$t('users.manage:error.user.not.found') })
          this.$router.push({ name: 'users.index' })
        })
    },

    // getUserDataByCpf () {
    //   this.attemptGetUserByCpf(this.formData.username.replace(/[^0-9]+/ig, ''))
    //     .then(({ data }) => {
    //       this.parseUserResponseData(data)
    //     })
    //     .catch(({ data }) => {
    //       if (data && data.error.message === 'user_already_registered') {
    //         this.formData.username = null
    //         this.setFeedback({ message: this.$t('users.manage:error.already.registered') })
    //       }
    //     })
    // },

    yesterday () {
      const date = new Date()

      date.setDate(date.getDate() - 1)

      return date.toISOString()
        .slice(0, 10)
    },

    getTargetAudienceValue (targetAudienceAnswer) {
      if (targetAudienceAnswer && targetAudienceAnswer.length && targetAudienceAnswer.length > 0) {
        return targetAudienceAnswer[0].text
      }

      return null
    },

    calculateAge (birthOfDate) {
      const birthDate = new Date(birthOfDate)
      const currentDate = new Date()

      let years = currentDate.getFullYear() - birthDate.getFullYear()
      const months = currentDate.getMonth() - birthDate.getMonth()

      if (months < 0 || (months === 0 && currentDate.getDate() < birthDate.getDate())) {
        years--
      }

      this.olderThan18 = years >= 18
    },

    addUserGroup (item) {
      if (!this.userGroups) {
        this.userGroups = []
      }

      if (item) {
        if (item.value === 'all') {
          this.userGroups = this.groupList
        } else {
          this.userGroups.push(item)
        }
      }
    },

    removeUserGroup (item) {
      if (item) {
        if (item.value === 'all') {
          this.userGroups = []
        } else {
          this.userGroups = this.userGroups.filter(group => group.value !== item.value)
        }
      }
    }
  }

}
</script>

<template>
  <Modal
    :active="!fetching"
    prevent-e-s-c
    @close="leave()"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('community:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ isEditing ? $t('community.index:modal.title.editing') :$t('community.index:modal.title.add') }}
      </h2>
      <Tabs
        v-if="hasStudentProfile"
        slot="tabs"
        :links="tabLinks"
        :index-active="tabLinkActive"
        dark
        @tabChange="changeTab($event)"
      />
      <form @submit.prevent="submit()">
        <!--
        <template v-if="tabLinkActive === 0">
-->
        <!--
          <radio :items="profileOptions" v-model="selectedProfile" horizontal dark></radio>
-->
        <!--
        </template>
-->
        <div v-show="tabLinkActive === 0">
          <InputField
            v-model="formData.username"
            :label="$t('global:form.login')"
            :validation="$v.formData.username"
            :readonly="!!isEditing"
            dark
          />
          <InputField
            v-model="formData.password"
            :label="$t('global:form.password')"
            type="password"
            autocomplete="password"
            :validation="$v.formData.password"
            dark
          />
          <Datepicker
            v-model="formData.data.birthOfDate"
            :label="$t('global:form.birthday')"
            min="1920-01-01"
            :max="yesterday()"
            dark
          />
          <InputField
            v-if="!olderThan18"
            v-model="formData.data.responsibleTutor"
            :label="$t('global:form.responsibleTutor')"
            dark
          />
          <InputField
            v-if="!olderThan18"
            v-model="formData.data.cpfResponsibleTutor"
            :label="$t('global:form.cpfResponsibleTutor')"
            mask="###.###.###-##"
            dark
          />
          <InputField
            v-model="formData.name"
            :label="$t('global:form.full.name')"
            autocomplete="name"
            :validation="$v.formData.name"
            dark
          />
          <InputField
            v-model="formData.email"
            :label="$t('global:form.email')"
            autocomplete="email"
            :validation="$v.formData.email"
            dark
          />
          <InputField
            v-model="formData.data.celular"
            :label="$t('global:form.phone')"
            mask="(##) #####-####"
            autocomplete="email"
            dark
          />
          <SelectField
            v-model="formData.data.gender"
            :label="$t('global:form.gender')"
            :items="genderOptions"
            dark
          />
          <SelectField
            v-model="formData.educationLevelId"
            :label="$t('global:form.education.level')"
            :items="educationLevels"
            dark
          />

          <SelectField
            v-if="isActiveTargetAudienceModule"
            v-model="formData.targetAudienceId"
            :label="$t('global:form.target.audience')"
            :items="parsedTargetAudiences"
            dark
          />

          <div
            v-if="isActiveTargetAudienceModule && formData.targetAudience &&
              formData.targetAudience.targetAudienceQuestion &&
              formData.targetAudience.targetAudienceQuestion.length > 0"
            dark
          >
            <div
              v-for="(field, fieldIndex) in formData.targetAudience.targetAudienceQuestion"
              :key="fieldIndex"
            >
              <InputField
                v-if="field.type === 'text'"
                v-model="field.value"
                :label="'Campo extra: ' + field.title"
                dark
              />
              <SelectField
                v-else-if="field.type === 'unique'"
                v-model="field.value"
                :label="'Campo extra: ' + field.title"
                :items="field.targetAudienceQuestionOption"
                dark
              />
            </div>
          </div>

          <template v-if="isRaeEnabled || isZipCodeEnabled">
            <InputField
              v-model="formData.data.address.cep"
              :label="$t('global:form.cep')"
              mask="#####-###"
              dark
            />
            <InputField
              v-model="formData.data.address.logradouro"
              :label="$t('global:form.street')"
              dark
            />
            <InputField
              v-model="formData.data.address.numero"
              :label="$t('global:form.number')"
              dark
            />
            <InputField
              v-model="formData.data.address.complemento"
              :label="$t('global:form.complement')"
              dark
            />
            <SelectField
              v-model="formData.data.address.id_state"
              :label="$t('global:form.state')"
              :items="states"
              dark
            />
            <SelectField
              v-model="formData.data.address.id_city"
              :label="$t('global:form.city')"
              :items="userCities"
              dark
            />
            <InputField
              v-model="formData.data.address.bairro"
              :label="$t('global:form.neighborhood')"
              dark
            />
          </template>

          <AppMultiselect
            v-model="userGroups"
            :options="groupList"
            :placeholder="$t('global:form.groups.select')"
            :validation="$v.formData.groups"
            dark
            @onSelect="addUserGroup"
            @onRemove="removeUserGroup"
          />

          <!--
 <FormSection :title="$t('users.manage:company.situation')">
            <Radio
              v-model="formData.company.situation_alias"
              :items="personSituationOptions"
              :validation="$v.formData.company.situation_alias"
              dark
            />
          </FormSection>
-->

          <FormSection
            v-if="!isEditing"
            title="Status do usuário"
          >
            <Radio
              v-model="formData.status"
              dark
              :items="userStatusOptions"
            />
          </FormSection>

          <FormSection :title="$t('users.manage:notification.type')">
            <Checkbox
              v-model="formData.data.notificationType.correio"
              :items="notificationOptions.correio"
              switch-type
              dark
            />
            <Checkbox
              v-model="formData.data.notificationType.email"
              :items="notificationOptions.email"
              switch-type
              dark
            />
            <Checkbox
              v-model="formData.data.notificationType.sms"
              :items="notificationOptions.sms"
              switch-type
              dark
            />
            <Checkbox
              v-model="formData.data.notificationType.telefone"
              :items="notificationOptions.telefone"
              switch-type
              dark
            />
          </FormSection>
        </div>
        <div v-show="tabLinkActive === 1">
          <InputField
            v-model="formData.company.cnpj"
            :label="$t('global:form.cnpj')"
            mask="##.###.###/####-##"
            autocomplete="username"
            dark
          />
          <InputField
            v-model="formData.company.companyName"
            :label="$t('global:form.social.name')"
            autocomplete="name"
            dark
          />
          <InputField
            v-model="formData.company.tradingName"
            :label="$t('global:form.fantasy.name')"
            autocomplete="name"
            dark
          />
          <Datepicker
            v-model="formData.company.foundingDate"
            :label="$t('global:form.foundation.date')"
            min="1900-01-01"
            :max="yesterday()"
            dark
          />
          <InputField
            v-model="formData.company.phone"
            :label="$t('global:form.phone')"
            :mask="['(##) ####-####', '(##) #####-####']"
            autocomplete="email"
            dark
          />
          <InputField
            v-model="formData.company.data.address.cep"
            :label="$t('global:form.cep')"
            mask="#####-###"
            dark
          />
          <InputField
            v-model="formData.company.data.address.logradouro"
            :label="$t('global:form.street')"
            dark
          />
          <InputField
            v-model="formData.company.data.address.numero"
            :label="$t('global:form.number')"
            dark
          />
          <InputField
            v-model="formData.company.data.address.complemento"
            :label="$t('global:form.complement')"
            dark
          />
          <SelectField
            v-model="formData.company.data.address.id_state"
            :label="$t('global:form.state')"
            :items="states"
            dark
          />
          <SelectField
            v-model="formData.company.data.address.id_city"
            :label="$t('global:form.city')"
            :items="companyCities"
            dark
          />
          <InputField
            v-model="formData.company.data.address.bairro"
            :label="$t('global:form.neighborhood')"
            dark
          />
          <SelectField
            v-model="formData.company.businessSectorIds"
            :label="$t('global:form.business.sector')"
            :items="businessSectors"
            multiple
            dark
          />
          <SelectField
            v-model="formData.company.businessSizeId"
            :label="$t('global:form.business.size')"
            :items="businessSizes"
            dark
          />
          <Autocomplete
            v-model="formData.company.cnaeIds"
            label="Tipo de empreendimento (CNAE)"
            :items="cnaes"
            async
            class="cnae-field"
            option-property="label"
            :max-value="9999"
            dark
            @search="fetchCnaes()"
          />
        </div>
        <div class="form-submit text-center">
          <Action
            :text="isEditing ? $t('global:save') : $t('global:register')"
            type="button"
            secondary
            large
            submit
            fixed-width
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style>
  .profiles-selection {
    margin-top: 60px;
  }

  .multiselect {
    margin: 20px 0;
  }
</style>
